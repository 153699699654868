import * as React from 'react';

import { Button, Form, Alert } from 'react-bootstrap';

interface publishModuleProps {
  password: string;
  albusDumbledoreSiteId: string;
  buildTime: number;
}

const PublishModule: React.FC<publishModuleProps> = ({
  password,
  albusDumbledoreSiteId,
  buildTime,
}: publishModuleProps) => {
  const albusDumbledoreAPIurl = 'https://albusdumbledore.enlighten-webservices.ovh';

  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
  const [passwordState, setPasswordState] = React.useState<string | null>(null);
  const [isAuthError, setIsAuthError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isSuccess, setIsSuccess] = React.useState<boolean | null>(null);

  if (!password || !albusDumbledoreSiteId) return null;

  const logIn = () => {
    if (passwordState === password) {
      setIsAuthError(false);
      setIsLoggedIn(true);
    } else {
      setIsAuthError(true);
    }
  };

  const handlePasswordChange = (event: any) => {
    setPasswordState(event.target.value);
  };

  const publishChanges = async () => {
    try {
      setIsLoading(true);
      setIsSuccess(null);

      await new Promise(resolve => setTimeout(resolve, 1500));

      const response = await fetch(`${albusDumbledoreAPIurl}/run-pipeline/${albusDumbledoreSiteId}`, {
        method: 'POST',
      });

      if (response.status !== 200) throw new Error('Status 500');

      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getButtonText = () => {
    if (isLoading) return 'Proszę czekać';
    if (isSuccess === true) return 'Sukces';
    if (isSuccess === false) return 'Spróbuj ponownie';
    return 'Opublikuj';
  };

  return (
    <>
      {!isLoggedIn && (
        <div>
          <p>Wpisz hasło, by kontynuować</p>
          {isAuthError && <Alert variant="danger">Niepoprawne hasło</Alert>}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Hasło</Form.Label>
              <Form.Control type="password" onChange={handlePasswordChange} />
            </Form.Group>
          </Form>
          <Button variant="dark" onClick={logIn}>
            Odblokuj
          </Button>
        </div>
      )}
      {isLoggedIn && (
        <div>
          <p>
            Wciśnij przycisk poniżej, aby wgrać nową wersję witryny (opublikować zmiany dokonane w panelu zarządzania
            Contentful CMS)
          </p>
          {isSuccess === false && (
            <Alert variant="danger">
              Niepowodzenie, spróbuj ponownie. Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną.
            </Alert>
          )}
          {isSuccess === true && (
            <Alert variant="success">
              Sukces. Poczekaj na publikację zmian (około {buildTime ? `${buildTime}` : `10`} min).
            </Alert>
          )}
          <Button variant="success" onClick={publishChanges} disabled={!!(isLoading || isSuccess)}>
            {getButtonText()}
          </Button>
        </div>
      )}
    </>
  );
};

export default PublishModule;
