import * as React from 'react';
import { Helmet } from 'react-helmet';

import MainLayout from '../layouts/MainLayout';

import PublishModule from '../components/publish/PublishModule';

import { Container, Row, Col } from 'react-bootstrap';

const Publish: React.FC = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Publikacja – Atakama Studio</title>
        <meta name="robots" content="noindex"></meta>
      </Helmet>

      <div className="publish">
        <Container className="publish__container">
          <Row>
            <Col>
              <h1>Publikacja</h1>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <PublishModule password="adwokat" albusDumbledoreSiteId="karol-oczkowicz" buildTime={10} />
            </Col>
          </Row>
        </Container>
      </div>
    </MainLayout>
  );
};

export default Publish;
